body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background-color: #cddef2;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px !important;
  text-align: center;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 10vw;
  }
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px !important;
  text-align: center;
}

h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px !important;
  text-align: center;
}

h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  text-align: center;
}

h5 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  text-align: center;
}

h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px !important;
  text-align: center;
}

.center-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #6c8dc8;
  background-color: #ffffff;
  border-radius: 8px;
  width: min(500px, 90vw - 10px);
  height: min(700px, 100vh - 30px);
  overflow-y: auto;
  margin-left: -7.5px;
  padding: 15px;
}

.subtitle {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.center-box .title {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
}

.center-box .logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: max(200px, 15vw);
  height: max(200px, 15vw);
}

.card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
}

.card-green {
  background-color: #93b172;
  opacity: 0.8;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-right {
  display: flex;
  justify-self: flex-end;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 10px;
}

.card-right .button {
  padding: 5px 10px;
}

.red {
  color: red;
  font-size: 0.8em;
}

.red-normal {
  color: red;
}

.green {
  color: green;
  font-size: 0.8em;
}

.green-normal {
  color: green;
}

.light {
  color: #444;
}

.form {
  display: block;
  width: 100%;
}

.form-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form input {
  width: calc(100% - 15px);
  height: 30px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #729564;
  padding-left: 10px;
  display: block;
}

.form input:focus {
  outline: none;
}

.button {
  background-color: #729564;
  border: none;
  color: white;
  padding: 15px 16px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.card-green .button {
  background-color: white;
  color: black;
}

.form input[type="submit"] {
  background-color: #93b172;
  width: 100%;
}

.button:focus {
  outline: none;
}

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.selector-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.selector-buttons button {
  flex: 1;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.selector-buttons button.active {
  background-color: #729564;
  color: #fff;
}

.selector-buttons .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.6rem;
}

.selector-buttons button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
